<template>
  <div class="container">
    <PublicHeader :totalName="$t('recharge').title" />
    <!-- 选择币类型 -->
    <van-popover v-model="showPopover" placement="bottom-start" :actions="actions" @select="onSelect">
      <template #reference>
        <div class="top" @click="showPopover = !showPopover">
          <span>{{ Currency }}</span> <img src="@/assets/img/select_d.png" width="11" />
        </div>
      </template>
    </van-popover>
    <!-- 选择通道 -->
    <div class="tabs">
      <div class="item" :class="WalletNetworkType == 'TRC20' ? 'active' : ''" @click="WalletNetworkType = 'TRC20'">
        TRC20
      </div>
      <div class="item" :class="WalletNetworkType == 'ERC20' ? 'active' : ''" @click="WalletNetworkType = 'ERC20'">
        ERC20
      </div>
      <div class="item" :class="WalletNetworkType == 'OMNI' ? 'active' : ''" @click="WalletNetworkType = 'OMNI'">
        OMNI
      </div>
    </div>
    <!-- 二维码 -->
    <div class="qrcode">
      <img :src="QrCode" width="115" />
      <div class="address">
        <div class="txt">{{ Address }}</div>
        <span @click="onCopy(Address)">{{ $t('recharge').copy }}</span>
      </div>
      <div class="tips">*{{ $t('recharge').tips1 }}{{ WalletNetworkType }}_{{ Currency }}{{ $t('recharge').tips2 }}！
      </div>
    </div>
    <!-- 充币数量 -->
    <div class="lable">{{ $t('recharge').amount }}</div>
    <van-field v-model="Amount" :placeholder="$t('recharge').amountMsg"></van-field>
    <!-- 转账凭证 -->
    <div class="lable">{{ $t('recharge').Voucher }}</div>
    <div class="unImg">
      <van-uploader :after-read="justAfterRead">
        <img v-if="Voucher" :src="Voucher" width="115" />
        <img v-else src="@/assets/img/up_img.png" width="115" />
      </van-uploader>
      <div class="txt">{{ $t('recharge').VoucherTips }}</div>
    </div>
    <!-- 按钮 -->
    <van-button color="#C92B2E" block style="border-radius: 10px;height: 50px;margin-top: 70px;" @click="handleSub">{{
      $t('recharge').submit }}</van-button>
  </div>
</template>

<script>
import userApi from '@/api/user'
import systemApi from '@/api/system'
import { mapGetters } from 'vuex'
import { getRandomString } from '@/utils/validate'
import PublicHeader from '@/components/publicHeader'
import { color } from 'echarts';

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      showPopover: false,
      addressList: [],
      actions: [
        { text: 'USDT' },
        { text: 'BTC' },
        { text: 'ETH' },
        { text: 'TRX' },
        { text: 'USDC' },
        { text: 'JiaShengTOKEN' },
      ],
      Currency: 'USDT',
      WalletNetworkType: 'TRC20',
      Voucher: '',
      Address: '',
      QrCode: '',
      showEdit: false,
      Amount: '',
    }
  },
  watch: {
    WalletNetworkType() {
      this.onChangeAll()
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  async mounted() {
    this.GetSystemWalletAddress()
    await userApi.getAccountBase()
  },
  methods: {
    //系统钱包地址
    async GetSystemWalletAddress() {
      const res = await systemApi.GetSystemWalletAddress()
      this.addressList = res
      this.onChangeAll()
    },
    //选择币种
    async onSelect(action) {
      this.Currency = action.text
      this.onChangeAll()
    },
    //选择变动地址图片
    onChangeAll() {
      var info = this.addressList.filter(item => item.Currency == this.Currency && item.WalletNetworkType == this.WalletNetworkType);
      if (info.length > 0) {
        this.Address = info[0].Address
        this.QrCode = info[0].QrCode
      }
    },
    // 凭证
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.Voucher = await systemApi.UploadFile(formdata)
    },
    //复制方法
    onCopy(text) {
      return new Promise((resolve) => {
        if (navigator.clipboard?.writeText) {
          this.$toast(this.$t('public').copySuccess)
          return resolve(navigator.clipboard.writeText(text))
        }
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        textarea.style.position = 'absolute'
        textarea.style.clip = 'rect(0 0 0 0)'
        textarea.value = text
        textarea.select()
        document.execCommand('copy', true)
        textarea.remove()
        this.$toast(this.$t('public').copySuccess)
        return resolve(true)
      })
    },
    // 提交
    async handleSub() {
      if (!this.Amount) {
        this.$toast({ message: this.$t('recharge').amountMsg })
        return false
      }
      if (!this.Voucher) {
        this.$toast({ message: this.$t('recharge').VoucherMsg })
        return false
      }
      const form = {
        Amount: this.Amount,
        Voucher: this.Voucher,
        Currency: this.Currency,
        WalletNetworkType: this.WalletNetworkType,
        Address: this.Address,
        QrCode: this.QrCode,
      }
      await userApi.Recharge(form)
      this.$toast({ message: this.$t('recharge').subMsg })
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 60px 15px 0 15px;
  background: #ffffff;

  .top {
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .tabs {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      width: 109px;
      height: 47px;
      background: #FFFFFF;
      border: 1px solid #CFCFCF;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #333333;
    }

    .active {
      font-weight: bold;
      color: #FFFFFF;
      border: none;
      background: #C92B2E;
      box-shadow: 0 2px 7px 1px rgba(195, 8, 12, 0.42);
    }
  }

  .qrcode {
    margin-top: 10px;
    padding: 13px;
    background: #F6F6F6;
    border-radius: 3px;
    text-align: center;

    .address {
      margin-top: 13px;
      height: 41px;
      background: #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;

      .txt {
        width: 230px;
        font-size: 13px;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span {
        border-left: 1px solid #D8D8D8;
        height: 20px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-size: 13px;
        color: #3485FF;
      }
    }

    .tips {
      margin-top: 10px;
      font-size: 12px;
      line-height: 17px;
      color: #FF9B37;
      text-align: left;

    }
  }

  .lable {
    margin: 15px 0 10px 0;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
  }

  .unImg {
    text-align: center;

    .txt {
      margin-top: 10px;
      font-size: 12px;
      color: #FF9B37;
    }
  }
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.van-cell {
  background: #F2F2F2;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
}

::v-deep .van-field__right-icon {
  margin: -10px 8px;
  padding: 0px;
  line-height: normal;
}
</style>
